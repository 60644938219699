@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

.bookContents {
  /* width: 100%; */
  /* overflow-x: auto;
    word-wrap: break-word; */
  line-height: 30px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 90%;
  text-align: left;
  font-size: 18px;
  background: #fff;
  /* padding-right: 40px; */

  /* Center slide text vertically */
  display: flex;
  justify-content: left;
  align-items: center;
}